<template>
  <v-dialog :value="value" width="720px" persistent @input="$emit('input', false)">
    <v-card class="dialog-content">
      <v-card-title
        ><span class="block-title">{{ title }}</span></v-card-title
      >
      <v-card-text>
        <v-row justify="center" align="center">
          <v-col class="pa-0">
            <v-time-picker v-model="start" format="24hr" locale="pt-BR" :max="finish"></v-time-picker>
          </v-col>
          <v-col class="pa-0" cols="2" style="text-align: center"><span>às</span></v-col>
          <v-col class="pa-0" cols="5">
            <v-time-picker v-model="finish" format="24hr" locale="pt-BR" :min="start"></v-time-picker>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="confirm">Confirmar</v-btn>
        <v-btn text @click="cancel">Cancelar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    save: {
      required: true,
      type: Function
    },
    value: Boolean,
    initialStart: String,
    initialFinish: String,
    title: {
      required: true,
      type: String
    }
  },
  data: () => ({
    start: null,
    finish: null
  }),
  mounted() {
    if (this.initialStart) this.start = this.initialStart
    if (this.initialFinish) this.finish = this.initialFinish
  },
  methods: {
    confirm() {
      this.save(this.start, this.finish)
      this.$emit('input', false)
    },
    cancel() {
      this.$emit('input', false)
    }
  }
}
</script>

<style lang="scss">
.dialog-content {
  .block-title {
    opacity: 0.8;
    font-size: 18px;
    font-weight: bold;
    color: #000000;
  }
}
</style>
